"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetMovingStreamData = exports.movingData = exports.movingStreamSlice = exports.fetchNewMovingStream = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    data: [],
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
};
exports.fetchNewMovingStream = (0, toolkit_1.createAsyncThunk)("movingCalendarStream/getData", ({ id, startDate, endDate }, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.apiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchSelectedDataRangeOfStream(id, startDate, endDate));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchNewMovingStream",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchSelectedDataRangeOfStream(id, startDate, endDate),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
exports.movingStreamSlice = (0, toolkit_1.createSlice)({
    name: "movingCalendarStream",
    initialState,
    reducers: {
        resetMovingStreamData: (state) => {
            state.data = [];
            state.status = "IDLE" /* StatusEnum.Idle */;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchNewMovingStream.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.data = action.payload;
            state.data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            state.error = null;
        })
            .addCase(exports.fetchNewMovingStream.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
            state.data = [];
        });
    },
});
exports.default = exports.movingStreamSlice.reducer;
const movingData = (state) => state.movingCalendarStream;
exports.movingData = movingData;
exports.resetMovingStreamData = exports.movingStreamSlice.actions.resetMovingStreamData;
