"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClusterPixelPosition = void 0;
const getClusterPixelPosition = (map, latLng) => {
    const overlay = new google.maps.OverlayView();
    overlay.setMap(map);
    const overlayProjection = overlay.getProjection();
    if (!overlayProjection) {
        throw new Error("Map projection is undefined");
    }
    const point = overlayProjection.fromLatLngToContainerPixel(latLng);
    if (!point) {
        throw new Error("Could not convert coordinates to point");
    }
    return point;
};
exports.getClusterPixelPosition = getClusterPixelPosition;
